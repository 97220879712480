import React, { useState, useEffect } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Rating from './Rating';

function Product({ product }) {
  const [isWishlisted, setIsWishlisted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      const wishlists = JSON.parse(localStorage.getItem('wishlists')) || {};
      const userWishlist = wishlists[userInfo._id] || [];
      if (userWishlist.some(item => item._id === product._id)) {
        setIsWishlisted(true);
      }
    }
  }, [product._id]);

  const handleWishlistToggle = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!userInfo) {
      navigate('/login');
      return;
    }

    let wishlists = JSON.parse(localStorage.getItem('wishlists')) || {};
    let userWishlist = wishlists[userInfo._id] || [];

    if (isWishlisted) {
      userWishlist = userWishlist.filter(item => item._id !== product._id);
    } else {
      userWishlist.push(product);
    }

    wishlists[userInfo._id] = userWishlist;
    localStorage.setItem('wishlists', JSON.stringify(wishlists));
    setIsWishlisted(!isWishlisted);
  };

  return (
    <Card className="my-3 p-3 rounded">
      <Link to={`/product/${product._id}`}>
        <Card.Img src={`/images/${product.image}`} className="product-image" />
      </Link>

      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as="div">
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as="div">
          <div className="my-3">
            <Rating
              value={product.average_rating}  // Use average rating from the Django model
              text={`${product.total_num_reviews} reviews`} // Use total number of reviews from the Django model
              color={'#f8e825'}
            />
          </div>
        </Card.Text>

        <ListGroup variant="flush">
          <ListGroup.Item><strong>Description:</strong> {product.description}</ListGroup.Item>
          <ListGroup.Item><strong>City:</strong> {product.city}</ListGroup.Item>
        </ListGroup>

        <div className="d-flex justify-content-between align-items-center mt-3">
          <button onClick={handleWishlistToggle} className="btn btn-light btn-sm">
            <i className={`fa-heart ${isWishlisted ? 'fas text-danger' : 'far'} fa-3x`}></i>
          </button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default Product;
