import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import axios from 'axios';
import { setUserInfo, getUserInfo } from '../components/localStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './LoginScreen.css';

function LoginScreen() {
    const location = useLocation();
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const redirect = location.search ? location.search.split('=')[1] : '/';
    
    useEffect(() => {
        const userInfo = getUserInfo();
        if (userInfo && userInfo.token) {
            navigate(redirect);
        }
    }, [navigate, redirect]);

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const config = { headers: { 'Content-Type': 'application/json' } };
            const { data } = await axios.post(
                '/api/users/login/',
                { username, password },
                config
            );
            setUserInfo(data);
            navigate('/');
            window.location.reload();
        } catch (err) {
            setError(
                err.response && err.response.data.detail
                    ? err.response.data.detail
                    : err.message
            );
        }
        setLoading(false);
    };

    return (
        <FormContainer>
            <div className="login-card">
                <h1 className="login-header">Log In</h1>
                {error && <Message variant="danger">{error}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId="username" className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter User email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="login-input"
                        />
                    </Form.Group>
                    <Form.Group controlId="password" className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <div className="password-input-wrapper">
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="login-input"
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                onClick={() => setShowPassword(!showPassword)}
                                className="password-toggle-icon"
                            />
                        </div>
                    </Form.Group>
                    <Button type="submit" variant="primary" className="login-button">
                        Log In
                    </Button>
                </Form>
                <Row className="py-3 align-items-center">
                    <Col className="d-flex justify-content-start">
                        <span className="bold-black-text">NEW CUSTOMER? </span>
                        <Link 
                            to={redirect !== '/' ? `/register?redirect=${redirect}` : '/register'}
                            className="bold-black-text"
                        >
                            REGISTER
                        </Link>
                    </Col>
                </Row>

                <Row className="py-3 justify-content-end" style={{ marginTop: 'auto' }}>
                    <Col>
                        Are you a service provider?{' '}
                        <Link to={redirect !== '/' ? `/owner-register?redirect=${redirect}` : '/owner-register'}>
                            Register here
                        </Link>
                    </Col>
                </Row>
            </div>
        </FormContainer>
    );
}

export default LoginScreen;
