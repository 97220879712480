import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ProductCarousel from '../components/ProductCarousel';
import DateCalculation from '../components/DateCalculation';
import HomeInvitePage from '../components/HomeInvitePage';
import AddProductPage from '../components/AddProductPage';
import ManagePage from '../components/ManagePage';
import './HomeScreen.css';

function HomeScreen() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasProduct, setHasProduct] = useState(false); // Added state for product registration check
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const keyword = queryParams.get('keyword') || '';
  const pageNumber = queryParams.get('page') || 1;
  const userInfo = useMemo(() => JSON.parse(localStorage.getItem('userInfo')) || {}, []);
  const role = userInfo.role;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get('/api/products/all', {
          params: {
            keyword: keyword,
            page: pageNumber,
          },
        });

        setProducts(data.products || []);
        setPage(data.page || 1);
        setPages(data.pages || 1);
      } catch (error) {
        setErrorMessage('Failed to fetch services. Please try again.');
        setSuccessMessage('');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [keyword, pageNumber]);

  useEffect(() => {
    // Check if the current user has a product registered
    const checkProduct = async () => {
      try {
        if (role === 'service-owner') {
          const config = {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          };
          const { data } = await axios.get('/api/products/mine/', config); // API endpoint to fetch the user's product
          setHasProduct(data.product !== null); // Assuming API returns `product` as null if no product is registered
        }
      } catch (error) {
        console.error('Error checking product:', error);
        setErrorMessage('Could not verify product registration.');
      }
    };

    checkProduct();
  }, [role, userInfo]);

  const handlePageChange = (newPage) => {
    navigate(`/?keyword=${keyword}&page=${newPage}`);
  };

  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight / 0.5, // Scroll to half the height of the viewport
      behavior: 'smooth', // Smooth scrolling
    });
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  return (
    <div>
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <>
        {/* Visible Only to Customers */}
        {role === 'customer' && (
          <div className="background-section">
            <div className="carousel-custom">
              <ProductCarousel />
              <HomeInvitePage />
            </div>
            <div className="title-bar">
              <h1 className="home-screen-title">Discover Our Exclusive Wedding Services</h1>
              <p className="welcome-message">
                At <span className="brand-name">BookYourCelebrations</span>, we help you find top-notch wedding services
                that make your day unforgettable. Browse our premium selection of services for an experience
                like no other.
              </p>
              <Button variant="outline-light" size="lg" className="explore-button" onClick={handleScroll}>
                Explore Services
              </Button>
            </div>

            <DateCalculation />
          </div>
        )}

       {/* Conditional Content Based on Role */}
{role === 'service-owner' && (
  hasProduct ? (
    <>
    
      <ManagePage />
    </>
  ) : (
    <AddProductPage />
  )
)}


        {role !== 'service-owner' && (
          <>
            {/* Loader, Error, and Product Display */}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <>
                {products.length === 0 ? (
                  <Message>No products found</Message>
                ) : (
                  <div className="product-grid">
                    {products.map((product) => (
                      <div key={product._id} className="product-card">
                        <Product product={product} />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div className="paginate-container">
              <Paginate page={page} pages={pages} keyword={keyword} handlePageChange={handlePageChange} />
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default HomeScreen;
