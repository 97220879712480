import React, { useState, useEffect } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'

function PaymentScreen() {
    const navigate = useNavigate();
    const shippingAddress = JSON.parse(localStorage.getItem('shippingAddress')) || {}
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('Visa')

    useEffect(() => {
        if (!shippingAddress.address) {
            navigate('/shipping');
        }
    }, [navigate, shippingAddress.address])

    const submitHandler = (e) => {
        e.preventDefault()
        localStorage.setItem('paymentMethod', paymentMethod)
        navigate('/placeorder');
    }

    return (
        <FormContainer>
            <CheckoutSteps step1 step2 step3 />

            <Form onSubmit={submitHandler}>
                <Form.Group>
                    <Form.Label as='legend'>Select Method</Form.Label>
                    <Col>
                        <Form.Check
                            type='radio'
                            label='Visa'
                            id='visa'
                            name='paymentMethod'
                            value='Visa'
                            checked={paymentMethod === 'Visa'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            label='MasterCard'
                            id='mastercard'
                            name='paymentMethod'
                            value='MasterCard'
                            checked={paymentMethod === 'MasterCard'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            label='American Express'
                            id='amex'
                            name='paymentMethod'
                            value='American Express'
                            checked={paymentMethod === 'American Express'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            label='Rupay'
                            id='rupay'
                            name='paymentMethod'
                            value='Rupay'
                            checked={paymentMethod === 'Rupay'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            label='Diners Club'
                            id='dinersclub'
                            name='paymentMethod'
                            value='Diners Club'
                            checked={paymentMethod === 'Diners Club'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            label='Net Banking'
                            id='netbanking'
                            name='paymentMethod'
                            value='Net Banking'
                            checked={paymentMethod === 'Net Banking'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        
                        <Form.Check
                            type='radio'
                            label='Paytm'
                            id='paytm'
                            name='paymentMethod'
                            value='Paytm'
                            checked={paymentMethod === 'Paytm'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            label='PhonePe'
                            id='phonepe'
                            name='paymentMethod'
                            value='PhonePe'
                            checked={paymentMethod === 'PhonePe'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            label='Mobikwik'
                            id='mobikwik'
                            name='paymentMethod'
                            value='Mobikwik'
                            checked={paymentMethod === 'Mobikwik'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <Form.Check
                            type='radio'
                            label='UPI (Google Pay, PhonePe, Paytm)'
                            id='upi'
                            name='paymentMethod'
                            value='UPI'
                            checked={paymentMethod === 'UPI'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                    </Col>
                </Form.Group>

                <Button type='submit' variant='primary'>
                    Continue
                </Button>
            </Form>
        </FormContainer>
    )
}

export default PaymentScreen
