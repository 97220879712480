import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Col, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './LoginScreen.css';
import axios from 'axios';

ChartJS.register(Title, Tooltip, Legend, ArcElement);


const BudgetScreen = () => {
  console.log('BudgetScreen rendered');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const token = userInfo?.access;
  const chartRef = useRef(null);
  const [loading, setLoading] = useState(false);
   // Reference to hold the chart instance
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#FF0000', '#FFFF00', '#4169E1', '#FF9F40'],
    }],
  });
  const [totalBudget, setTotalBudget] = useState(0);
  const [expenses, setExpenses] = useState({
    venue: null,
    jewels: null,
    catering: null,
    decoration: null,
    photography: null,
    entertainment: null
  });
  const [customExpense, setCustomExpense] = useState('');
  const [customAmount, setCustomAmount] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.chartInstance; // Access the chart instance
      if (chartInstance) {
        console.log('Destroying existing chart instance');
        chartInstance.destroy();
      }
    }

    const chartInstance = new ChartJS(chartRef.current, {
      type: 'pie',
      data: chartData,
      options: {
        responsive: true,
      },
    });

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [chartData]);

  const handleBudgetChange = useCallback((e) => {
    setTotalBudget(parseFloat(e.target.value) || 0);
  }, []);

  const handleExpenseChange = useCallback((section, value) => {
    setExpenses(prevExpenses => ({
      ...prevExpenses,
      [section]: parseFloat(value) || 0
    }));
  }, []);

  const handleAddExpense = useCallback(() => {
    if (customExpense) {
      setExpenses(prevExpenses => ({
        ...prevExpenses,
        [customExpense]: parseFloat(customAmount) || 0
      }));
      setCustomExpense('');
      setCustomAmount(0);
    }
  }, [customExpense, customAmount]);

  useEffect(() => {
    const labels = Object.keys(expenses).map(section => section.charAt(0).toUpperCase() + section.slice(1));
    const data = Object.values(expenses);

    setChartData({
      labels,
      datasets: [{
        data,
        backgroundColor: ['#FF6384', '#FFCE56', '#36A2EB', '#FF9F9A', '#FF9F40'],
      }],
    });
  }, [expenses]);
  const fetchBudget = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo')); // Get the latest user info
    const pk = userInfo.id; // Assuming 'id' is the user's unique identifier
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`, // Authorization header with token
      },
    };
  
    axios
      .get(`/api/orders/get-budget/${pk}/`, config) // Pass the pk in the URL
      .then((response) => {
        if (response.data) {
          setTotalBudget(response.data.total_budget || 0);
          setExpenses(response.data.expenses || {});
        }
      })
      .catch((error) => {
        setErrorMessage('Error fetching budget. Please try again.');
        console.error('Error fetching budget:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Call fetchBudget when the component mounts
  React.useEffect(() => {
    fetchBudget();
  }, []);

  const handleSave = () => {
    const data = {
      total_budget: totalBudget,
      expenses: expenses,
    };
  
    const userInfo = JSON.parse(localStorage.getItem('userInfo')); // Ensure you're getting the latest user info
    const pk = userInfo.id; // Assuming 'id' is the user's unique identifier
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`, // Authorization header with token
      },
    };
  
    // Add pk to the URL
    axios
      .post(`/api/orders/update-budget/${pk}/`, data, config) // Include pk in the URL path
      .then((response) => {
        setSuccessMessage('Budget saved successfully!');
        setErrorMessage('');
      })
      .catch((error) => {
        setErrorMessage('Failed to save budget. Please try again.');
        setSuccessMessage('');
        console.error('Error saving budget:', error);
      });
  };
  

  return (
    <div className="container">
      <Row className="py-3 justify-content-end" style={{ marginTop: 'auto' }}>
        <Col>
          <h3>Wedding Budget</h3>
          <form>
            <h4>Enter Total Budget</h4>
            <table className="table-form">
              <tbody>
                <tr className="mb-3">
                  <td><label htmlFor="totalBudget" className="form-label">Total Budget</label></td>
                  <td>
                    <input
                      type="number"
                      id="totalBudget"
                      className="form-control"
                      value={totalBudget}
                      onChange={handleBudgetChange}
                      placeholder="Enter total budget"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <h4>Enter Expenses for Categories</h4>
            <table className="table-form">
              <tbody>
                {Object.keys(expenses).map(section => (
                  <tr key={section} className="mb-3">
                    <td>
                      <label htmlFor={`expense-${section}`} className="form-label">
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                      </label>
                    </td>
                    <td>
                      <input
                        type="number"
                        id={`expense-${section}`}
                        className="form-control"
                        value={expenses[section]}
                        onChange={(e) => handleExpenseChange(section, e.target.value)}
                        placeholder={`Enter expense for ${section}`}
                      />
                    </td>
                  </tr>
                ))}

                <tr className="mb-3">
                  <td>
                    <input
                      type="text"
                      id="customExpense"
                      className="form-control"
                      value={customExpense}
                      onChange={(e) => setCustomExpense(e.target.value)}
                      placeholder="Enter custom expense name"
                    />
                  </td>
                <td className="position-relative custom-input-group">
                    <input
                      type="number"
                      id="customAmount"
    className="form-control custom-input"
                      value={customAmount}
                      onChange={(e) => setCustomAmount(parseFloat(e.target.value) || 0)}
                      placeholder="Enter amount"
                    />
                    <button
                      type="button"
    className="custom-button"
                      onClick={handleAddExpense}
                    >
    +
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <h3>Total Expenses</h3>
      <table className="table-form">
        <tbody>
          <tr className="mb-3">
            <td><strong>Total Expenses</strong></td>
            <td>{Object.values(expenses).reduce((acc, expense) => acc + expense, 0)}</td>
          </tr>
          <tr className="mb-3">
            <td><strong>Budget Status</strong></td>
            <td>{`You are ${Object.values(expenses).reduce((acc, expense) => acc + expense, 0) > totalBudget ? 'exceeding' : 'within'} your budget by ${Math.abs(totalBudget - Object.values(expenses).reduce((acc, expense) => acc + expense, 0))}`}</td>
          </tr>
        </tbody>
      </table>
          <Button variant="success" onClick={handleSave}>
            Save Budget
          </Button>

          {successMessage && <p className="text-success mt-3">{successMessage}</p>}
          {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
        </Col>
        <Col>
          <h3>Expenses Breakdown</h3>
          <div className="chart-container">
            <canvas ref={chartRef} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BudgetScreen;
